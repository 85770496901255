import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'

export default {
  async getWeekByCourseSemester(params) {
    const response = await axios.get('uni/course/semesters/get_week', { params } )
    const { data } = response
    if (data.code === ApiCode.SUCCESS) {
      return data.data
    }
    return null
  }

}
